<template>
  <div class="content-page">
    <div class="content-nav">
      <el-breadcrumb class="breadcrumb" separator="/">
        <el-breadcrumb-item :to="{ path: '/dashboard/brandcatelist' }">分类列表</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="operation-nav">
        <router-link to="/dashboard/brandcateadd">
          <el-button type="primary" icon="plus">添加分类</el-button>
        </router-link>
      </div>
    </div>
    <div class="content-main">
      <div class="form-table-box">
        <el-table :data="tableData" style="width: 100%" border stripe>
          <el-table-column prop="name" label="ID" width="80">
            <template scope="scope">
              <div>{{ scope.row.id }}</div>
            </template>
          </el-table-column>

          <el-table-column prop="name" label="分类名称">
            <template scope="scope">
              <div>{{ scope.row.name }}</div>
            </template>
          </el-table-column>

          <el-table-column label="是否显示" width="80">
            <template scope="scope">
              <el-switch
                v-model="scope.row.is_show"
                active-text=""
                inactive-text=""
                :active-value="1"
                :inactive-value="0"
                @change="changeShowStatus($event, scope.row.id)"
              >
              </el-switch>
            </template>
          </el-table-column>

          <el-table-column prop="delete" label="状态">
            <template scope="scope">
              <div>{{ scope.row.delete == 1 ? '已删除' : '正常' }}</div>
            </template>
          </el-table-column>

          <el-table-column prop="sort" label="排序" width="100" sortable>
            <template scope="scope">
              <el-input
                v-model="scope.row.sort"
                placeholder="排序"
                @blur="submitSort(scope.$index, scope.row)"
              ></el-input>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="300">
            <template scope="scope">
              <el-button size="small" type="primary" @click="handleRowEdit(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                size="small"
                type="danger"
                @click="handleRowDelete(scope.$index, scope.row)"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      total: 0,
      filterForm: {
        name: ''
      },
      tableData: []
    };
  },
  methods: {
    changeShowStatus($event, para) {
      this.axios
        .get('brand/brandCateShow', {
          params: {
            status: $event,
            id: para
          }
        })
        .then((response) => {});
    },

    submitSort(index, row) {
      this.axios
        .post('brand/brandCateUpdateSort', { id: row.id, sort: row.sort })
        .then((response) => {});
    },
    handleRowEdit(index, row) {
      this.$router.push({ name: 'brandcateadd', query: { id: row.id } });
    },
    handleRowDelete(index, row) {
      this.$confirm('确定要删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.post('brand/brandCateDestory', { id: row.id }).then((response) => {
          console.log(response.data);
          if (response.data.errno === 0) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });

            this.getList();
          } else {
            this.$message({
              type: 'error',
              message: '删除失败!'
            });
          }
        });
      });
    },
    onSubmitFilter() {
      this.page = 1;
      this.getList();
    },
    getList() {
      this.axios
        .get('brand/brandCateList', {
          params: {
            page: this.page,
            name: this.filterForm.name
          }
        })
        .then((response) => {
          this.tableData = response.data.data;
        });
    }
  },
  components: {},
  mounted() {
    this.getList();
  }
};
</script>

<style scoped>
.sub-category .el-table__expanded-cell {
  padding: 0;
}

.bg-gray {
  /* background:gray; */
  color: red;
  font-weight: bold;
}

.bg-left {
  margin-left: 30px;
}
</style>
